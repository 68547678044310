"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_LIST_HEADER_TABLET = exports.USER_LIST_HEADER = exports.BRANCH_TASKS_LIST_HEADER_TABLET = exports.BRANCH_TASKS_LIST_HEADER = exports.ASSET_TRANSFERS_LIST_HEADER_TABLET = exports.ASSET_TRANSFERS_LIST_HEADER = exports.SUPPORT_TASKS_LIST_HEADER_TABLET_VIEW = exports.SUPPORT_TASKS_LIST_HEADER = exports.DEVICE_CONFIG_LIST_HEADER_TABLET_VIEW = exports.DEVICE_CONFIG_LIST_HEADER = exports.INSTALL_LIST_HEADER_TABLET_VIEW = exports.INSTALL_LIST_HEADER = exports.OUTAGE_LIST_HEADER_TABLET_VIEW = exports.OUTAGE_LIST_HEADER = exports.CUSTOMER_LIST_HEADER = exports.SITE_LIST_HEADER = exports.ERROR_LIST_HEADER_TABLET_VIEW = exports.ERROR_LIST_HEADER = exports.ASSET_LIST_HEADER_TABLET_VIEW = exports.ASSET_LIST_HEADER = exports.getDefaultSortHeader = exports.OPPOSITE_SORT_DIRECTION = exports.DEFAULT_SORT_DIRECTION = exports.SortDirection = void 0;
const _Helpers_1 = require("@Helpers");
var SortDirection;
(function (SortDirection) {
    SortDirection["ASC"] = "asc";
    SortDirection["DESC"] = "desc";
})(SortDirection || (exports.SortDirection = SortDirection = {}));
exports.DEFAULT_SORT_DIRECTION = SortDirection.ASC;
exports.OPPOSITE_SORT_DIRECTION = SortDirection.DESC;
/**
 * Returns default header if defined
 * @param headerGroupList
 * @returns default header | undefined
 */
const getDefaultSortHeader = (headerGroupList) => {
    const headerFlatList = headerGroupList.reduce((acc, hg) => [...acc, ...hg.header], []);
    return headerFlatList.find((h) => h.isDefault);
};
exports.getDefaultSortHeader = getDefaultSortHeader;
exports.ASSET_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '5%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Asset ID', value: 'asset_id_ext', sortable: true, isDefault: true }],
        width: '8%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Type', value: 'asset_type', sortable: true }],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Branch', value: 'branch_name', sortable: true }],
        width: '16%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Site Address', value: 'site_address', sortable: true }],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Customer', value: 'customer_name', sortable: true },
            { title: 'Contracts', value: '', sortable: false },
        ],
        width: '17%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Monitoring', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Stage', value: 'deployment', sortable: true }],
        width: '8%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
    },
]);
exports.ASSET_LIST_HEADER_TABLET_VIEW = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '9%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Asset ID', value: 'asset_id_ext', sortable: true, isDefault: true }],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Type', value: 'asset_type', sortable: true }],
        width: '12%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Branch', value: 'branch_name', sortable: true }],
        width: '19%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Site Address', value: 'site_address', sortable: true }],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Customer', value: 'customer_name', sortable: true },
            { title: 'Contracts', value: '', sortable: false },
        ],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '10%',
        justifyContent: 'center',
    },
]);
exports.ERROR_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [
            { title: 'Category', value: 'category', sortable: true },
            { title: 'Priority', value: 'priority', sortable: true, isDefault: true },
        ],
        width: '10%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Asset ID', value: 'asset_id_ext', sortable: true },
            { title: 'IP', value: 'router_ip_address', sortable: true },
        ],
        width: '7%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Asset Type', value: 'asset_type', sortable: true }],
        width: '7%',
    },
    {
        header: [
            { title: 'Sub Category', value: 'sub_category', sortable: true },
            { title: 'Solution', value: 'solution', sortable: true },
        ],
        width: '7%',
    },
    {
        header: [
            { title: 'Error ID', value: '', sortable: false },
            { title: 'Code', value: 'error_type_code', sortable: true },
        ],
        width: '8%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Timestamp', value: 'occurred_at', sortable: true }],
        width: '8%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Branch', value: 'branch_name', sortable: true }],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Error', value: 'details', sortable: true }],
        width: '25%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Notes', value: 'notes', sortable: true }],
        width: '5%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Status', value: 'status', sortable: true }],
        width: '5%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
    },
]);
exports.ERROR_LIST_HEADER_TABLET_VIEW = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [
            { title: 'Category', value: 'category', sortable: true },
            { title: 'Priority', value: 'priority', sortable: true, isDefault: true },
        ],
        width: '10%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Asset ID', value: 'asset_id_ext', sortable: true },
            { title: 'IP', value: 'router_ip_address', sortable: true },
            { title: 'Asset Type', value: 'asset_type', sortable: true },
        ],
        width: '16%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Error ID', value: '', sortable: false },
            { title: 'Code', value: 'error_type_code', sortable: true },
            { title: 'Sub Category', value: 'sub_category', sortable: true },
            { title: 'Solution', value: 'solution', sortable: true },
        ],
        width: '13%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Timestamp', value: 'occurred_at', sortable: true },
            { title: 'Branch', value: 'branch_name', sortable: true },
        ],
        width: '18%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Status', value: 'status', sortable: true },
            { title: 'Error', value: 'details', sortable: true },
            { title: 'Notes', value: 'notes', sortable: true },
        ],
        width: '35%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
    },
]);
exports.SITE_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Site Name', value: 'site_name', sortable: true }],
        width: '18%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Customer', value: 'customer_name', sortable: true },
            { title: 'ID', value: 'customer_id', sortable: false },
        ],
        width: '18%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Site Address', value: 'address', sortable: true }],
        width: '30%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Branch', value: 'branch_name', sortable: true },
            { title: 'ID', value: 'branch_id', sortable: false },
        ],
        width: '16%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '10%',
        justifyContent: 'center',
    },
]);
exports.CUSTOMER_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '10%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Customer', value: 'name', sortable: true, isDefault: true },
            { title: 'ID', value: 'customer_id_ext', sortable: true },
        ],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Cash Account', value: 'is_cash_account', sortable: true }],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Billing Address', value: 'billing_address', sortable: true }],
        width: '30%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Active Sites', value: 'num_active_sites', sortable: true }],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Active Assets', value: 'num_active_assets', sortable: true }],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '10%',
        justifyContent: 'center',
    },
]);
exports.OUTAGE_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '6%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Asset ID', value: 'asset_id_ext', sortable: true, isDefault: true }],
        width: '9%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Type', value: 'asset_type', sortable: true }],
        width: '12%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Asset', value: 'asset_offline_hours', sortable: true }],
        width: '7%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Camera', value: 'camera_offline_hours', sortable: true }],
        width: '7%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Comms', value: 'cboard_offline_hours', sortable: true }],
        width: '7%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Reconeyez', value: 'reconeyez_offline_hours', sortable: true }],
        width: '7%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Customer', value: 'customer_name', sortable: true },
            { title: 'Branch', value: 'branch_name', sortable: true },
        ],
        width: '19%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Address', value: 'site_address', sortable: true }],
        width: '18%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
        isCheckbox: false,
    },
]);
exports.OUTAGE_LIST_HEADER_TABLET_VIEW = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Asset ID', value: 'asset_id_ext', sortable: true, isDefault: true },
            { title: 'Type', value: 'asset_type', sortable: true },
        ],
        width: '16%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Asset', value: 'asset_offline_hours', sortable: true }],
        width: '7%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Camera', value: 'camera_offline_hours', sortable: true }],
        width: '7%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Comms', value: 'cboard_offline_hours', sortable: true }],
        width: '7%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Reconeyez', value: 'reconeyez_offline_hours', sortable: true }],
        width: '9%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Customer', value: 'customer_name', sortable: true },
            { title: 'Branch', value: 'branch_name', sortable: true },
        ],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Address', value: 'site_address', sortable: true }],
        width: '21%',
        justifyContent: 'flex-start',
    },
]);
exports.INSTALL_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '6%',
        justifyContent: 'center',
        isCheckbox: true,
    },
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '6%',
        justifyContent: 'center',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Asset ID', value: 'asset_id_ext', sortable: true }],
        width: '8%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Type', value: 'asset_type_name', sortable: true }],
        width: '8%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Action Date', value: 'action_date', sortable: true, isDefault: true }],
        width: '8%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Hire Status (Asset Hire)', value: 'asset_hire_status_name', sortable: false }],
        width: '16%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Customer', value: 'customer_name', sortable: true }],
        width: '20%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Address', value: 'address', sortable: false }],
        width: '20%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
        isCheckbox: false,
    },
]);
exports.INSTALL_LIST_HEADER_TABLET_VIEW = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '6%',
        justifyContent: 'center',
        isCheckbox: true,
    },
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '6%',
        justifyContent: 'center',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Asset ID', value: 'asset_id_ext', sortable: true }],
        width: '12%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Type', value: 'asset_type_name', sortable: true }],
        width: '12%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Action Date', value: 'action_date', sortable: true, isDefault: true }],
        width: '12%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Hire Status (Asset Hire)', value: 'asset_hire_status_name', sortable: false }],
        width: '16%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Customer', value: 'customer_name', sortable: true }],
        width: '18%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Address', value: 'address', sortable: false }],
        width: '18%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
]);
exports.DEVICE_CONFIG_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
        isCheckbox: true,
    },
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Short ID', value: 'short_id', sortable: true }],
        width: '10%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Device ID', value: 'unique_id', sortable: true }],
        width: '25%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Type', value: 'device_type_name', sortable: true }],
        width: '8%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'ICCID', value: 'iccid', sortable: true }],
        width: '26%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '15%',
        justifyContent: 'center',
        isCheckbox: false,
    },
]);
exports.DEVICE_CONFIG_LIST_HEADER_TABLET_VIEW = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
        isCheckbox: true,
    },
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
        isCheckbox: false,
    },
    {
        header: [
            { title: 'Short ID', value: 'short_id', sortable: true },
            { title: 'Device ID', value: 'unique_id', sortable: true },
        ],
        width: '25%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Type', value: 'device_type_name', sortable: true }],
        width: '8%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'ICCID', value: 'iccid', sortable: true }],
        width: '26%',
        justifyContent: 'flex-start',
        isCheckbox: false,
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '25%',
        justifyContent: 'center',
        isCheckbox: false,
    },
]);
exports.SUPPORT_TASKS_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Asset ID', value: 'asset_id_ext', sortable: true }],
        width: '9%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Type', value: 'asset_type_name', sortable: true }],
        width: '9%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Task Type', value: 'task_types_name', sortable: true }],
        width: '12%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Branch', value: 'branch_name', sortable: true },
            { title: 'Customer', value: 'customer_name', sortable: true },
        ],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Address', value: 'address', sortable: false }],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Notes', value: 'notes', sortable: true }],
        width: '5%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Created Date', value: 'created_at', sortable: true, isDefault: true },
            { title: 'Resolved Date', value: 'resolved_at', sortable: true },
        ],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '7%',
        justifyContent: 'center',
    },
]);
exports.SUPPORT_TASKS_LIST_HEADER_TABLET_VIEW = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '9%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Asset ID', value: 'asset_id_ext', sortable: true },
            { title: 'Type', value: 'asset_type_name', sortable: true },
        ],
        width: '17%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Task Type', value: 'task_types_name', sortable: true }],
        width: '14%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Branch', value: 'branch_name', sortable: true },
            { title: 'Customer', value: 'customer_name', sortable: true },
            { title: 'Address', value: 'address', sortable: false },
        ],
        width: '30%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Notes', value: 'notes', sortable: true }],
        width: '7%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Created Date', value: 'created_at', sortable: true, isDefault: true },
            { title: 'Resolved Date', value: 'resolved_at', sortable: true },
        ],
        width: '14%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '9%',
        justifyContent: 'center',
    },
]);
exports.ASSET_TRANSFERS_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Asset ID', value: 'asset_id_ext', sortable: true }],
        width: '9%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Type', value: 'asset_type', sortable: true }],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Branch From', value: 'branch_from_name', sortable: true }],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '6%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Branch To', value: 'branch_to_name', sortable: true, isDefault: true }],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Notes', value: 'notes', sortable: true }],
        width: '5%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Transfer Date', value: 'resolved_at', sortable: true }],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '12%',
        justifyContent: 'center',
    },
]);
exports.ASSET_TRANSFERS_LIST_HEADER_TABLET = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '7%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Asset ID', value: 'asset_id_ext', sortable: true },
            { title: 'Type', value: 'asset_type', sortable: true },
        ],
        width: '16%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Branch From', value: 'branch_from_name', sortable: true }],
        width: '24%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Branch To', value: 'branch_to_name', sortable: true, isDefault: true }],
        width: '24%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Notes', value: 'notes', sortable: true },
            { title: 'Transfer Date', value: 'resolved_at', sortable: true },
        ],
        width: '15%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '14%',
        justifyContent: 'center',
    },
]);
exports.BRANCH_TASKS_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Asset ID', value: 'asset_id_ext', sortable: true }],
        width: '9%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Type', value: 'asset_type_name', sortable: true }],
        width: '9%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Task Type', value: 'task_types_name', sortable: true }],
        width: '12%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Sending Branch', value: 'branch_from_name', sortable: true }],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Branch', value: 'branch_to_name', sortable: true }],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Notes', value: 'notes', sortable: true }],
        width: '5%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Created Date', value: 'created_at', sortable: true, isDefault: true },
            { title: 'Resolved Date', value: 'resolved_at', sortable: true },
        ],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '7%',
        justifyContent: 'center',
    },
]);
exports.BRANCH_TASKS_LIST_HEADER_TABLET = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '9%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Asset ID', value: 'asset_id_ext', sortable: true },
            { title: 'Type', value: 'asset_type_name', sortable: true },
        ],
        width: '17%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Task Type', value: 'task_types_name', sortable: true }],
        width: '14%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Sending Branch', value: 'branch_from_name', sortable: true },
            { title: 'Branch', value: 'branch_to_name', sortable: true },
        ],
        width: '30%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Notes', value: 'notes', sortable: true }],
        width: '7%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Created Date', value: 'created_at', sortable: true, isDefault: true },
            { title: 'Resolved Date', value: 'resolved_at', sortable: true },
        ],
        width: '14%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '9%',
        justifyContent: 'center',
    },
]);
exports.USER_LIST_HEADER = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '6%',
        justifyContent: 'center',
    },
    {
        header: [{ title: 'Full Name', value: 'full_name', sortable: true }],
        width: '17%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Email', value: 'email', sortable: true }],
        width: '17%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Role', value: 'user_roles', sortable: true }],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Access', value: 'user_access', sortable: false }],
        width: '20%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Created At', value: 'created_at', sortable: true, isDefault: true },
            { title: 'Last Action', value: 'last_action', sortable: true },
        ],
        width: '10%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '10%',
        justifyContent: 'center',
    },
]);
exports.USER_LIST_HEADER_TABLET = (0, _Helpers_1.addIds2levels)('header', [
    {
        header: [{ title: '', value: '', sortable: false }],
        width: '8%',
        justifyContent: 'center',
    },
    {
        header: [
            { title: 'Full Name', value: 'full_name', sortable: true },
            { title: 'Email', value: 'email', sortable: true },
        ],
        width: '22%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Role', value: 'user_roles', sortable: true }],
        width: '22%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Access', value: 'user_access', sortable: false }],
        width: '22%',
        justifyContent: 'flex-start',
    },
    {
        header: [
            { title: 'Created At', value: 'created_at', sortable: true, isDefault: true },
            { title: 'Last Action', value: 'last_action', sortable: true },
        ],
        width: '14%',
        justifyContent: 'flex-start',
    },
    {
        header: [{ title: 'Action', value: '', sortable: false }],
        width: '12%',
        justifyContent: 'center',
    },
]);
