"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deviceUpdateAPI = exports.DEVICE_UPDATE_ACTION = exports.deviceRemoveAPI = exports.DEVICE_REMOVE_ACTION = exports.deviceEventImageUrlsAPI = exports.deviceEventListAPI = exports.deviceConfigUpdateAPI = void 0;
const _Api_1 = require("@Api");
// https://gitlab.atfvision.net.au/vision-new-platform/vision-hub-backend/-/wikis/API-Device#action-update
const deviceConfigUpdateAPI = (device, lenses) => {
    var _a;
    return (0, _Api_1.api)('device', {
        action: 'DEVICE_CONFIG_UPDATE',
        fillable: {
            device: {
                id: device.id,
                configuration: Object.assign(Object.assign({}, (((_a = device.device_type_info) === null || _a === void 0 ? void 0 : _a.configuration) || {})), { lenses }),
            },
        },
    });
};
exports.deviceConfigUpdateAPI = deviceConfigUpdateAPI;
const deviceEventListAPI = (id, product) => (0, _Api_1.api)('device', {
    action: 'DEVICE_EVENT_LIST',
    fillable: {
        device: {
            id,
            product,
        },
    },
});
exports.deviceEventListAPI = deviceEventListAPI;
const deviceEventImageUrlsAPI = (id, event_sequence, product) => (0, _Api_1.api)('device', {
    action: 'DEVICE_EVENT_IMAGE_URLS',
    fillable: {
        device: {
            id,
            event_sequence,
            product,
        },
    },
});
exports.deviceEventImageUrlsAPI = deviceEventImageUrlsAPI;
exports.DEVICE_REMOVE_ACTION = 'DEVICE_REMOVE';
const deviceRemoveAPI = (id) => (0, _Api_1.api)('device', {
    action: exports.DEVICE_REMOVE_ACTION,
    fillable: {
        device: {
            id,
        },
    },
});
exports.deviceRemoveAPI = deviceRemoveAPI;
exports.DEVICE_UPDATE_ACTION = 'DEVICE_UPDATE';
const deviceUpdateAPI = (device) => (0, _Api_1.api)('device', {
    action: exports.DEVICE_UPDATE_ACTION,
    fillable: {
        device,
    },
});
exports.deviceUpdateAPI = deviceUpdateAPI;
