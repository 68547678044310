"use strict";
// ----------------------------
// ----- Auth / User ----------
// ----------------------------
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtInfoEntityType = exports.TaskType = exports.AuditTrailModel = exports.HirePurposeDeletable = exports.HirePurposeType = exports.LookupTable = exports.FieldSize = exports.FieldType = exports.HirePurpose = exports.AssetHireForm = exports.CopyHireSteps = exports.CountryCode = exports.WeekDay = exports.SettingType = exports.SearchFilterType = exports.AssetErrorStatusDesc = exports.AssetErrorStatus = exports.SolutionName = exports.ErrorSubCategory = exports.ErrorCategory = exports.ErrorPriority = exports.DeploymentStatus = exports.PolygonBehav = exports.PolygonType = exports.PolygonKey = exports.TabKey = exports.PackageName = exports.DiagnosticStatus = exports.Env = exports.HireStatus = exports.HealthStatus = exports.AuthStatus = exports.RoleGroup = exports.Role = void 0;
var Role;
(function (Role) {
    Role["UNDEFINED"] = "";
    Role["SUPERADMIN"] = "SUPERADMIN";
    Role["ACCESS1"] = "ACCESS1";
    Role["ACCESS2"] = "ACCESS2";
    Role["ACCESS3"] = "ACCESS3";
    Role["ACCESS4"] = "ACCESS4";
})(Role || (exports.Role = Role = {}));
var RoleGroup;
(function (RoleGroup) {
    RoleGroup["UNDEFINED"] = "";
    RoleGroup["SUPERADMIN"] = "SUPERADMIN";
    RoleGroup["DEVELOPERS"] = "DEVELOPERS";
    RoleGroup["SUPPORT"] = "SUPPORT";
    RoleGroup["MANUFACTURING"] = "MANUFACTURING";
    RoleGroup["BRANCH"] = "BRANCH";
    RoleGroup["ASSET_MANAGER"] = "ASSET MANAGER";
    RoleGroup["TECH_SUPPORT"] = "TECHNICAL SUPPORT";
})(RoleGroup || (exports.RoleGroup = RoleGroup = {}));
var AuthStatus;
(function (AuthStatus) {
    AuthStatus["UNAUTHENTICATED"] = "UNAUTHENTICATED";
    AuthStatus["MFA_NEED_SETUP"] = "MFA_NEED_SETUP";
    AuthStatus["MFA_NEED_CODE"] = "MFA_NEED_CODE";
    AuthStatus["HAS_DEFAULT_PWD"] = "HAS_DEFAULT_PWD";
    AuthStatus["AUTHENTICATED"] = "AUTHENTICATED";
})(AuthStatus || (exports.AuthStatus = AuthStatus = {}));
// ----------------------------
// -------- Asset -------------
// ----------------------------
var HealthStatus;
(function (HealthStatus) {
    HealthStatus["OFFLINE"] = "OFF-LINE";
    HealthStatus["CRITICAL"] = "CRITICAL";
    HealthStatus["HIGH"] = "HIGH";
    HealthStatus["MEDIUM"] = "MEDIUM";
    HealthStatus["NORMAL"] = "NORMAL";
    HealthStatus["ONLINE"] = "ON-LINE";
    HealthStatus["NOTPROVIDED"] = "NOT-PROVIDED";
    HealthStatus["NOTAPPLICABLE"] = "NOT-APPLICABLE";
    HealthStatus["WARNING"] = "WARNING";
    HealthStatus["MISSING"] = "MISSING";
    HealthStatus["UNDEFINED"] = "";
})(HealthStatus || (exports.HealthStatus = HealthStatus = {}));
var HireStatus;
(function (HireStatus) {
    HireStatus["NOTPROVIDED"] = "NOT-PROVIDED";
    HireStatus["WOFF"] = "WOFF";
    HireStatus["REFURB"] = "REFURB";
    HireStatus["DEMO"] = "DEMO";
    HireStatus["DAMAGED"] = "DAMAGED";
    HireStatus["TEST"] = "TEST";
    HireStatus["USEDBYVI"] = "USEDBYVI";
    HireStatus["RETURNED"] = "RETURNED";
    HireStatus["BULKITEM"] = "BULKITEM";
    HireStatus["SOLD"] = "SOLD";
    HireStatus["ONTRIAL"] = "ONTRIAL";
    HireStatus["ONSUBSCRIPTION"] = "ONSUBSCRIPTION";
    HireStatus["ATRESELLER"] = "ATRESELLER";
    HireStatus["LOSTASSETS"] = "LOSTASSETS";
    HireStatus["ON_HIRE"] = "ON-HIRE";
    HireStatus["OFF_HIRE_ONSITE"] = "OFF-HIRE-ONSITE";
    HireStatus["ON_HIRE_PENDING_INSTALL"] = "ON-HIRE-PENDING-INSTALL";
    HireStatus["ON_HIRE_PENDING_CONFIRM"] = "ON-HIRE-PENDING-CONFIRM";
    HireStatus["ON_HIRE_MONITORING_PENDING"] = "ON-HIRE-MONITORING-PENDING";
    HireStatus["ON_HIRE_CHANGE"] = "ON-HIRE-CHANGE";
    HireStatus["OFF_HIRE_ONSITE_PENDING"] = "OFF-HIRE-ONSITE-PENDING";
    HireStatus["OFF_HIRE_PENDING_UNINSTALL"] = "OFF-HIRE-PENDING-UNINSTALL";
    HireStatus["OFF_HIRE_RETURNED_PENDING"] = "OFF-HIRE-RETURNED-PENDING";
    HireStatus["OFF_HIRE_RETURNED"] = "OFF-HIRE-RETURNED";
})(HireStatus || (exports.HireStatus = HireStatus = {}));
var Env;
(function (Env) {
    Env["LOCAL"] = "local";
    Env["DEV"] = "dev";
    Env["PROD"] = "prod";
    Env["INVALID"] = "invalid";
    Env["UAT"] = "uat";
})(Env || (exports.Env = Env = {}));
var DiagnosticStatus;
(function (DiagnosticStatus) {
    DiagnosticStatus["RUNNING"] = "RUNNING";
    DiagnosticStatus["SUCCESS"] = "SUCCESS";
    DiagnosticStatus["FAILED"] = "FAILED";
})(DiagnosticStatus || (exports.DiagnosticStatus = DiagnosticStatus = {}));
var PackageName;
(function (PackageName) {
    PackageName["VA"] = "Video_Analytics";
    PackageName["RegCheck"] = "RegCheck";
    PackageName["Legacy_RegoCheck"] = "RegoCheck";
    PackageName["Vision_Sight"] = "Vision_Sight_Mx";
})(PackageName || (exports.PackageName = PackageName = {}));
var TabKey;
(function (TabKey) {
    TabKey["video_analytics"] = "video_analytics";
    TabKey["mobotix"] = "mobotix";
    TabKey["regcheck"] = "regcheck";
})(TabKey || (exports.TabKey = TabKey = {}));
var PolygonKey;
(function (PolygonKey) {
    PolygonKey["alert_zones"] = "alert_zones";
    PolygonKey["record_zones"] = "record_zones";
    PolygonKey["exclusion_zones"] = "exclusion_zones";
    PolygonKey["people_standing"] = "people_standing";
    PolygonKey["rego_zones"] = "rego_zones";
})(PolygonKey || (exports.PolygonKey = PolygonKey = {}));
var PolygonType;
(function (PolygonType) {
    PolygonType["ALERT_ZONE"] = "Alert Zone";
    PolygonType["RECORD_ZONE"] = "Record Zone";
    PolygonType["EXCLUSION_ZONE"] = "Excl Zone";
    PolygonType["PEOPLE_STANDING_ZONE"] = "People";
    PolygonType["REGO_ZONE"] = "Rego Zone";
})(PolygonType || (exports.PolygonType = PolygonType = {}));
var PolygonBehav;
(function (PolygonBehav) {
    PolygonBehav["POLYGON"] = "Polygon";
    PolygonBehav["RECTANGLE"] = "Rectangle";
})(PolygonBehav || (exports.PolygonBehav = PolygonBehav = {}));
var DeploymentStatus;
(function (DeploymentStatus) {
    DeploymentStatus["NOT_PROVIDED"] = "NOT_PROVIDED";
    DeploymentStatus["INSTALLING"] = "INSTALLING";
    DeploymentStatus["UPDATING"] = "UPDATING";
    DeploymentStatus["RUNNING"] = "RUNNING";
    DeploymentStatus["FAILED"] = "FAILED";
    DeploymentStatus["WARNING"] = "WARNING";
})(DeploymentStatus || (exports.DeploymentStatus = DeploymentStatus = {}));
// ----------------------------
// ------ Asset Error ---------
// ----------------------------
var ErrorPriority;
(function (ErrorPriority) {
    ErrorPriority["critical"] = "critical";
    ErrorPriority["high"] = "high";
    ErrorPriority["medium"] = "medium";
    ErrorPriority["info"] = "info";
})(ErrorPriority || (exports.ErrorPriority = ErrorPriority = {}));
var ErrorCategory;
(function (ErrorCategory) {
    ErrorCategory["hardware"] = "hardware";
    ErrorCategory["software"] = "software";
    ErrorCategory["network"] = "network";
    ErrorCategory["storage"] = "storage";
    ErrorCategory["power"] = "power";
    ErrorCategory["timelapse"] = "timelapse";
})(ErrorCategory || (exports.ErrorCategory = ErrorCategory = {}));
var ErrorSubCategory;
(function (ErrorSubCategory) {
    ErrorSubCategory["asset"] = "asset";
    ErrorSubCategory["battery"] = "battery";
    ErrorSubCategory["camera"] = "camera";
    ErrorSubCategory["charge"] = "charge";
    ErrorSubCategory["diagnostic"] = "diagnostic";
    ErrorSubCategory["disconnect"] = "disconnect";
    ErrorSubCategory["end_of_life"] = "end_of_life";
    ErrorSubCategory["commsboard"] = "commsboard";
    ErrorSubCategory["external"] = "external";
    ErrorSubCategory["general"] = "general";
    ErrorSubCategory["local"] = "local";
    ErrorSubCategory["mains"] = "mains";
    ErrorSubCategory["package"] = "package";
    ErrorSubCategory["router"] = "router";
    ErrorSubCategory["communication"] = "communication";
})(ErrorSubCategory || (exports.ErrorSubCategory = ErrorSubCategory = {}));
var SolutionName;
(function (SolutionName) {
    SolutionName["RegCheck"] = "RegCheck";
    SolutionName["EnviroGuard"] = "EnviroGuard";
    SolutionName["Capture"] = "Capture";
})(SolutionName || (exports.SolutionName = SolutionName = {}));
// When adding new statuses, make sure you add also the description in the enum with matching key
var AssetErrorStatus;
(function (AssetErrorStatus) {
    AssetErrorStatus["active"] = "active";
    AssetErrorStatus["active_asset_type_ignored"] = "active_asset_type_ignored";
    AssetErrorStatus["active_offhire_ignored"] = "active_offhire_ignored";
    AssetErrorStatus["resolved"] = "resolved";
})(AssetErrorStatus || (exports.AssetErrorStatus = AssetErrorStatus = {}));
var AssetErrorStatusDesc;
(function (AssetErrorStatusDesc) {
    AssetErrorStatusDesc["active"] = "Active";
    AssetErrorStatusDesc["active_asset_type_ignored"] = "Active Asset Type Ignored";
    AssetErrorStatusDesc["active_offhire_ignored"] = "Active Off-Hire Ignored";
    AssetErrorStatusDesc["resolved"] = "Resolved";
})(AssetErrorStatusDesc || (exports.AssetErrorStatusDesc = AssetErrorStatusDesc = {}));
// ----------------------------
// ------ Search Filters ------
// ----------------------------
var SearchFilterType;
(function (SearchFilterType) {
    SearchFilterType["ID"] = "id";
    SearchFilterType["TEXT"] = "text";
    SearchFilterType["ID_TEXT"] = "id_text";
    SearchFilterType["BOOLEAN"] = "boolean";
    SearchFilterType["HASCONTENT"] = "hascontent";
})(SearchFilterType || (exports.SearchFilterType = SearchFilterType = {}));
var SettingType;
(function (SettingType) {
    SettingType["RANGE"] = "range";
    SettingType["OPTIONS"] = "options";
    SettingType["TEXT"] = "text";
})(SettingType || (exports.SettingType = SettingType = {}));
var WeekDay;
(function (WeekDay) {
    WeekDay["Mon"] = "Mon";
    WeekDay["Tue"] = "Tue";
    WeekDay["Wed"] = "Wed";
    WeekDay["Thu"] = "Thu";
    WeekDay["Fri"] = "Fri";
    WeekDay["Sat"] = "Sat";
    WeekDay["Sun"] = "Sun";
})(WeekDay || (exports.WeekDay = WeekDay = {}));
// --------------------------------
// ----------  Country  -----------
// --------------------------------
var CountryCode;
(function (CountryCode) {
    CountryCode["AUSTRALIA"] = "AU";
    CountryCode["NEWZEALAND"] = "NZ";
    CountryCode["UNDEFINED"] = "";
})(CountryCode || (exports.CountryCode = CountryCode = {}));
var CopyHireSteps;
(function (CopyHireSteps) {
    CopyHireSteps["COPY_ASSET_HIRE_FORM"] = "COPY_ASSET_HIRE_FORM";
    CopyHireSteps["EDIT_DESCRIPTION_FORM"] = "EDIT_DESCRIPTION_FORM";
    CopyHireSteps["HIRE_PURPOSES_DISPALY"] = "HIRE_PURPOSES_DISPALY";
})(CopyHireSteps || (exports.CopyHireSteps = CopyHireSteps = {}));
// --------------------------------
// ---------- Asset Hire ----------
// --------------------------------
var AssetHireForm;
(function (AssetHireForm) {
    AssetHireForm["NEW_EDIT_HIRE_FORM"] = "NEW_EDIT_HIRE_FORM";
    AssetHireForm["NEW_EDIT_PURPOSE_FORM"] = "NEW_EDIT_PURPOSE_FORM";
    AssetHireForm["INSTALL_ACTION_FORM"] = "INSTALL_ACTION_FORM";
    AssetHireForm["OFF_HIRE_ACTION_FORM"] = "OFF_HIRE_ACTION_FORM";
    AssetHireForm["COPY_ASSET_HIRE_ACTION_FORM"] = "COPY_ASSET_HIRE_ACTION_FORM";
    AssetHireForm["APPROVE_MONITORING_ACTION_FORM"] = "APPROVE_MONITORING_ACTION_FORM";
    AssetHireForm["REJECT_MONITORING_ACTION_FORM"] = "REJECT_MONITORING_ACTION_FORM";
    AssetHireForm["RETURN_ASSET_ACTION_FORM"] = "RETURN_ASSET_ACTION_FORM";
    AssetHireForm["ON_HIRE_ACTION_FORM"] = "ON_HIRE_ACTION_FORM";
    AssetHireForm["CROSS_STREET_FORM"] = "CROSS_STREET_FORM";
    AssetHireForm["CONFIRM_OFF_HIRE_REMINDER_MODAL"] = "CONFIRM_OFF_HIRE_REMINDER_MODAL";
    AssetHireForm["AUDIT_TRAIL"] = "AUDIT_TRAIL";
})(AssetHireForm || (exports.AssetHireForm = AssetHireForm = {}));
var HirePurpose;
(function (HirePurpose) {
    HirePurpose["recording"] = "recording";
    HirePurpose["monitoring"] = "monitoring";
    HirePurpose["number_plate_recognition"] = "number_plate_recognition";
    HirePurpose["time_lapse"] = "time_lapse";
    HirePurpose["reconeyez"] = "reconeyez";
    HirePurpose["gate_control"] = "gate_control";
    HirePurpose["site_illumination"] = "site_illumination";
    HirePurpose["asset_not_charged"] = "asset_not_charged";
    HirePurpose["other"] = "other";
})(HirePurpose || (exports.HirePurpose = HirePurpose = {}));
var FieldType;
(function (FieldType) {
    FieldType["options"] = "options";
    FieldType["text"] = "text";
    FieldType["date"] = "date";
    FieldType["time"] = "time";
    FieldType["boolean"] = "boolean";
    FieldType["email_list"] = "email_list";
    FieldType["week_days"] = "week_days";
    FieldType["lookup"] = "lookup";
    FieldType["internal"] = "internal";
})(FieldType || (exports.FieldType = FieldType = {}));
var FieldSize;
(function (FieldSize) {
    FieldSize["full"] = "full";
    FieldSize["half"] = "half";
})(FieldSize || (exports.FieldSize = FieldSize = {}));
var LookupTable;
(function (LookupTable) {
    LookupTable["monitoring_providers"] = "monitoring_providers";
    LookupTable["charged_reasons"] = "charged_reasons";
})(LookupTable || (exports.LookupTable = LookupTable = {}));
var HirePurposeType;
(function (HirePurposeType) {
    HirePurposeType["object"] = "object";
    HirePurposeType["list"] = "list";
})(HirePurposeType || (exports.HirePurposeType = HirePurposeType = {}));
var HirePurposeDeletable;
(function (HirePurposeDeletable) {
    HirePurposeDeletable["never"] = "never";
    HirePurposeDeletable["before_hire"] = "before_hire";
    HirePurposeDeletable["always"] = "always";
})(HirePurposeDeletable || (exports.HirePurposeDeletable = HirePurposeDeletable = {}));
// ---------------------------------
// ----------    Shared   ----------
// ---------------------------------
var AuditTrailModel;
(function (AuditTrailModel) {
    AuditTrailModel["ASSET"] = "asset";
    AuditTrailModel["ASSET_HIRE"] = "assethire";
    AuditTrailModel["SITE"] = "site";
    AuditTrailModel["DEVICE"] = "device";
    AuditTrailModel["USER"] = "user";
})(AuditTrailModel || (exports.AuditTrailModel = AuditTrailModel = {}));
// --------------------------------
// --------- Branch Task ----------
// --------------------------------
var TaskType;
(function (TaskType) {
    TaskType["asset_transfer"] = "Asset Transfer";
    TaskType["confirm_on_hire"] = "Confirm On Hire";
    TaskType["approve_change_request"] = "Approve Change Request";
    TaskType["confirm_or_reject_monitoring_request"] = "Confirm or Reject Monitoring Request";
    TaskType["confirm_off_hire_request"] = "Confirm Off Hire Request";
    TaskType["timelapse_compilation_request"] = "Time-lapse Compilation Request";
    TaskType["health_check_request"] = "Health Check Request";
    TaskType["footage_request"] = "Footage Request";
    TaskType["zones_check_request"] = "Zones Check Request";
    TaskType["review_monitoring_request"] = "Review Monitoring Request";
    TaskType["relocation_health_check_request"] = "Relocation Health Check Request";
})(TaskType || (exports.TaskType = TaskType = {}));
// --------------------------------
// -------- External Info ---------
// --------------------------------
var ExtInfoEntityType;
(function (ExtInfoEntityType) {
    ExtInfoEntityType["asset"] = "asset";
})(ExtInfoEntityType || (exports.ExtInfoEntityType = ExtInfoEntityType = {}));
