"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.manufacturingConfigurationDamagedAPI = exports.MANUFACTURING_CONFIGURATION_DAMAGED_ACTION = exports.manufacturingConfigurationCreateAssetsAPI = exports.MANUFACTURING_CONFIGURATION_CREATE_ASSETS_ACTION = exports.manufacturingConfigurationCancelAPI = exports.MANUFACTURING_CONFIGURATION_CANCEL_ACTION = exports.manufacturingConfigurationAddAPI = exports.MANUFACTURING_CONFIGURATION_ADD_ACTION = exports.manufacturingConfigurationLookupAPI = exports.manufacturingConfigurationExportAPI = exports.MANUFACTURING_CONFIGURATION_EXPORT_ACTION = exports.manufacturingConfigurationListAPI = exports.MANUFACTURING_CONFIGURATION_LIST_ACTION = void 0;
const _Api_1 = require("@Api");
const _Constants_1 = require("@Constants");
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
exports.MANUFACTURING_CONFIGURATION_LIST_ACTION = 'MANUFACTURING_CONFIGURATION_LIST';
const manufacturingConfigurationListAPI = (basic_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = _Constants_1.ITEM_PER_PAGE) => (0, _Api_1.api)('manufacturing_configuration', {
    action: exports.MANUFACTURING_CONFIGURATION_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    fillable: {
        basic_search,
    },
});
exports.manufacturingConfigurationListAPI = manufacturingConfigurationListAPI;
exports.MANUFACTURING_CONFIGURATION_EXPORT_ACTION = 'MANUFACTURING_CONFIGURATION_EXPORT';
const manufacturingConfigurationExportAPI = (basic_search, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC) => (0, _Api_1.api)('manufacturing_configuration', {
    action: exports.MANUFACTURING_CONFIGURATION_EXPORT_ACTION,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    fillable: {
        basic_search,
    },
}, undefined, undefined, true, true, undefined, undefined, true);
exports.manufacturingConfigurationExportAPI = manufacturingConfigurationExportAPI;
const manufacturingConfigurationLookupAPI = (search_term) => (0, _Api_1.api)('manufacturing_configuration', {
    action: 'MANUFACTURING_CONFIGURATION_LOOKUP',
    fillable: {
        search_term,
    },
});
exports.manufacturingConfigurationLookupAPI = manufacturingConfigurationLookupAPI;
exports.MANUFACTURING_CONFIGURATION_ADD_ACTION = 'MANUFACTURING_CONFIGURATION_ADD';
const manufacturingConfigurationAddAPI = (device_ids) => (0, _Api_1.api)('manufacturing_configuration', {
    action: exports.MANUFACTURING_CONFIGURATION_ADD_ACTION,
    fillable: {
        device: {
            device_ids,
        },
    },
});
exports.manufacturingConfigurationAddAPI = manufacturingConfigurationAddAPI;
exports.MANUFACTURING_CONFIGURATION_CANCEL_ACTION = 'MANUFACTURING_CONFIGURATION_CANCEL';
const manufacturingConfigurationCancelAPI = (device_ids) => (0, _Api_1.api)('manufacturing_configuration', {
    action: exports.MANUFACTURING_CONFIGURATION_CANCEL_ACTION,
    fillable: {
        device: {
            device_ids,
        },
    },
});
exports.manufacturingConfigurationCancelAPI = manufacturingConfigurationCancelAPI;
exports.MANUFACTURING_CONFIGURATION_CREATE_ASSETS_ACTION = 'MANUFACTURING_CONFIGURATION_CREATE_ASSETS';
const manufacturingConfigurationCreateAssetsAPI = (device_ids) => (0, _Api_1.api)('manufacturing_configuration', {
    action: exports.MANUFACTURING_CONFIGURATION_CREATE_ASSETS_ACTION,
    fillable: {
        device: {
            device_ids,
        },
    },
});
exports.manufacturingConfigurationCreateAssetsAPI = manufacturingConfigurationCreateAssetsAPI;
exports.MANUFACTURING_CONFIGURATION_DAMAGED_ACTION = 'MANUFACTURING_CONFIGURATION_DAMAGED';
const manufacturingConfigurationDamagedAPI = (device_ids) => (0, _Api_1.api)('manufacturing_configuration', {
    action: exports.MANUFACTURING_CONFIGURATION_DAMAGED_ACTION,
    fillable: {
        device: {
            device_ids,
        },
    },
});
exports.manufacturingConfigurationDamagedAPI = manufacturingConfigurationDamagedAPI;
