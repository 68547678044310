"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reconeyezSignal = exports.reconeyezBattery = void 0;
/**
 * Gets battery percentage for reconeyez asset
 */
const reconeyezBattery = (asset) => { var _a, _b; return ((_b = (_a = (asset.devices || [])[0]) === null || _a === void 0 ? void 0 : _a.device_type_info) === null || _b === void 0 ? void 0 : _b.battery_percent) || -1; };
exports.reconeyezBattery = reconeyezBattery;
/**
 * Gets signal strength for reconeyez asset
 */
const reconeyezSignal = (asset) => { var _a, _b; return ((_b = (_a = (asset.devices || [])[0]) === null || _a === void 0 ? void 0 : _a.device_type_info) === null || _b === void 0 ? void 0 : _b.uplink_signal) || null; };
exports.reconeyezSignal = reconeyezSignal;
