"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TIME_RANGE_OPTS = exports.USER_DEFAULT_FILTERS = exports.BRANCH_TASKS_DEFAULT_FILTERS = exports.SUPPORT_TASKS_DEFAULT_FILTERS = exports.ASSET_TRANSFERS_DEFAULT_FILTERS = exports.CUSTOMER_AND_SITE_DEFAULT_FILTERS = exports.ASSET_ERROR_DEFAULT_FILTERS = exports.SUPERADMIN_ROLE_ID = exports.REGEX_GEOLOCATION = exports.PHONE_REGEX = exports.REGEX_EMAIL = exports.SEARCH_FOR_STAGES = exports.HIRE_STATUS_WITH_BADGE = exports.TEST_ENV_ARR = exports.VA_SETTINGS_SKELETON_ARR = exports.SKELETON_ARR_2 = exports.SKELETON_ARR = exports.NO_RECORD = exports.RECONEYEZ_ASSETS = exports.RECONEYEZ_DEVICES = exports.COMS = exports.CAMERAS = exports.ITEM_PER_PAGE = void 0;
const interfaces_1 = require("@Interfaces/interfaces");
// Assets
exports.ITEM_PER_PAGE = 50;
exports.CAMERAS = ['S15', 'S16', 'M15', 'M26', 'i25', 'MOV', 'AXS'];
exports.COMS = ['PCD', 'RAS', 'FIT', 'RS3', 'NT6'];
exports.RECONEYEZ_DEVICES = ['RBG', 'RSI', 'RDT', 'RKP', 'RUN'];
exports.RECONEYEZ_ASSETS = ['RECONEYEZBRIDGE', 'RECONEYEZSIREN', 'RECONEYEZDETECTOR', 'RECONEYEZKEYPAD'];
exports.NO_RECORD = 'No Record';
exports.SKELETON_ARR = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
exports.SKELETON_ARR_2 = [0, 1];
exports.VA_SETTINGS_SKELETON_ARR = [0, 1, 2];
exports.TEST_ENV_ARR = ['DEV', 'LOCAL'];
// Install
exports.HIRE_STATUS_WITH_BADGE = [
    interfaces_1.HireStatus.ON_HIRE_PENDING_INSTALL,
    interfaces_1.HireStatus.OFF_HIRE_PENDING_UNINSTALL,
    interfaces_1.HireStatus.OFF_HIRE_ONSITE_PENDING,
    interfaces_1.HireStatus.OFF_HIRE_ONSITE,
];
// Stages
exports.SEARCH_FOR_STAGES = 'Search for stages ...';
// Validation Regular Expressions
exports.REGEX_EMAIL = /^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// eslint-disable-next-line max-len
exports.PHONE_REGEX = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/;
exports.REGEX_GEOLOCATION = /^-([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
// Roles and groups
exports.SUPERADMIN_ROLE_ID = 1;
// Errors
exports.ASSET_ERROR_DEFAULT_FILTERS = [
    {
        key: 'error_statuses',
        label: 'Error Statuses',
        search_by: interfaces_1.SearchFilterType.ID,
        default_search_by: 'text',
        values: [
            {
                id: 1,
                name: 'active',
                description: 'Active',
            },
            {
                id: 2,
                name: 'resolved',
                description: 'Resolved',
            },
        ],
        isByText: false,
        ids: [1],
        text: [],
        value: null,
    },
    {
        key: 'error_priorities',
        label: 'Error Priorities',
        search_by: interfaces_1.SearchFilterType.ID_TEXT,
        default_search_by: 'id',
        values: [
            {
                id: 1,
                name: 'critical',
                description: 'Critical',
            },
            {
                id: 2,
                name: 'high',
                description: 'High',
            },
            {
                id: 3,
                name: 'medium',
                description: 'Medium',
            },
            {
                id: 4,
                name: 'info',
                description: 'Info',
            },
        ],
        isByText: false,
        ids: [1, 2, 3],
        text: [],
        value: null,
    },
];
// Sites
exports.CUSTOMER_AND_SITE_DEFAULT_FILTERS = [
    {
        key: 'is_active',
        label: 'Active',
        search_by: interfaces_1.SearchFilterType.BOOLEAN,
        default_search_by: interfaces_1.SearchFilterType.BOOLEAN,
        values: [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ],
        isByText: false,
        ids: [],
        text: [],
        value: true,
    },
];
// Asset Transfers
exports.ASSET_TRANSFERS_DEFAULT_FILTERS = [
    {
        key: 'resolved_at',
        label: 'Transferred',
        search_by: interfaces_1.SearchFilterType.HASCONTENT,
        default_search_by: interfaces_1.SearchFilterType.HASCONTENT,
        values: [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ],
        isByText: false,
        ids: [],
        text: [],
        value: false,
    },
];
// Support Tasks
exports.SUPPORT_TASKS_DEFAULT_FILTERS = [
    {
        key: 'resolved_at',
        label: 'Resolved',
        search_by: interfaces_1.SearchFilterType.HASCONTENT,
        default_search_by: interfaces_1.SearchFilterType.HASCONTENT,
        values: [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ],
        isByText: false,
        ids: [],
        text: [],
        value: false,
    },
];
// Branch Tasks
exports.BRANCH_TASKS_DEFAULT_FILTERS = [
    {
        key: 'resolved_at',
        label: 'Resolved',
        search_by: interfaces_1.SearchFilterType.HASCONTENT,
        default_search_by: interfaces_1.SearchFilterType.HASCONTENT,
        values: [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ],
        isByText: false,
        ids: [],
        text: [],
        value: false,
    },
];
// User
exports.USER_DEFAULT_FILTERS = [
    {
        key: 'is_active',
        label: 'Active',
        search_by: interfaces_1.SearchFilterType.BOOLEAN,
        default_search_by: interfaces_1.SearchFilterType.BOOLEAN,
        values: [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ],
        isByText: false,
        ids: [],
        text: [],
        value: true,
    },
];
// Plot Time Range
exports.TIME_RANGE_OPTS = [
    { id: 0, value: 24, option: '24hr' },
    { id: 1, value: 72, option: '3 Days' },
    { id: 2, value: 168, option: '7 Days' },
    { id: 3, value: 336, option: '14 Days' },
    { id: 4, value: 720, option: '30 Days' },
    { id: 5, value: 2160, option: '90 Days' },
];
