"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.manualGetHubGeneralManualUrlAPI = exports.MANUAL_GET_HUB_GENERAL_MANUAL_URL_ACTION = exports.manualGetReconeyezManualUrlAPI = exports.MANUAL_GET_RECONEYEZ_MANUAL_URL_ACTION = exports.manualGetUserManagementManualUrlAPI = exports.MANUAL_GET_USER_MANAGEMENT_MANUAL_URL_ACTION = exports.manualGetReleaseNotesUrlAPI = exports.MANUAL_GET_RELEASE_NOTES_URL_ACTION = exports.manualGetSupportManualUrlAPI = exports.MANUAL_GET_SUPPORT_MANUAL_URL_ACTION = exports.manualGetBranchManualUrlAPI = exports.MANUAL_GET_BRANCH_MANUAL_URL_ACTION = void 0;
const _Api_1 = require("@Api");
exports.MANUAL_GET_BRANCH_MANUAL_URL_ACTION = 'MANUAL_GET_BRANCH_MANUAL_URL';
const manualGetBranchManualUrlAPI = () => (0, _Api_1.api)('manual', {
    action: exports.MANUAL_GET_BRANCH_MANUAL_URL_ACTION,
});
exports.manualGetBranchManualUrlAPI = manualGetBranchManualUrlAPI;
exports.MANUAL_GET_SUPPORT_MANUAL_URL_ACTION = 'MANUAL_GET_SUPPORT_MANUAL_URL';
const manualGetSupportManualUrlAPI = () => (0, _Api_1.api)('manual', {
    action: exports.MANUAL_GET_SUPPORT_MANUAL_URL_ACTION,
});
exports.manualGetSupportManualUrlAPI = manualGetSupportManualUrlAPI;
exports.MANUAL_GET_RELEASE_NOTES_URL_ACTION = 'MANUAL_GET_RELEASE_NOTES_URL';
const manualGetReleaseNotesUrlAPI = () => (0, _Api_1.api)('manual', {
    action: exports.MANUAL_GET_RELEASE_NOTES_URL_ACTION,
});
exports.manualGetReleaseNotesUrlAPI = manualGetReleaseNotesUrlAPI;
exports.MANUAL_GET_USER_MANAGEMENT_MANUAL_URL_ACTION = 'MANUAL_GET_USER_MANAGEMENT_MANUAL_URL';
const manualGetUserManagementManualUrlAPI = () => (0, _Api_1.api)('manual', {
    action: exports.MANUAL_GET_USER_MANAGEMENT_MANUAL_URL_ACTION,
});
exports.manualGetUserManagementManualUrlAPI = manualGetUserManagementManualUrlAPI;
exports.MANUAL_GET_RECONEYEZ_MANUAL_URL_ACTION = 'MANUAL_GET_RECONEYEZ_MANUAL_URL';
const manualGetReconeyezManualUrlAPI = () => (0, _Api_1.api)('manual', {
    action: exports.MANUAL_GET_RECONEYEZ_MANUAL_URL_ACTION,
});
exports.manualGetReconeyezManualUrlAPI = manualGetReconeyezManualUrlAPI;
exports.MANUAL_GET_HUB_GENERAL_MANUAL_URL_ACTION = 'MANUAL_GET_HUB_GENERAL_MANUAL_URL';
const manualGetHubGeneralManualUrlAPI = () => (0, _Api_1.api)('manual', {
    action: exports.MANUAL_GET_HUB_GENERAL_MANUAL_URL_ACTION,
});
exports.manualGetHubGeneralManualUrlAPI = manualGetHubGeneralManualUrlAPI;
